@use "styles/mixins/setFonts" as *;

.cta {
  background-color: #F6F6F7;

  &__heading {
    display: flex;
    justify-content: center;
    padding: 100px 15px 0;
    position: relative;

    .inner {
      max-width: 1300px;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 2fr 1fr;

      .title {
        grid-column-start: 2;
        text-align: center;
      }

      .label {
        padding-left: 76px;

        .text {
          font-family: "Caveat", cursive;
          font-size: 27px;
          transform: rotate(8.247deg);
          max-width: 240px;
          margin-left: auto;
          position: relative;

          &:before {
            content: '';
            display: block;
            width: 76px;
            height: 62px;
            background: url("/common/vectors/curclePointer.svg") no-repeat center;
            position: absolute;
            top: 60%;
            right: 100%;
          }
        }
      }
    }
  }

  &__container {
    max-width: 1200px;
    padding: 70px 15px 90px;
    margin: 0 auto;
  }

  &__carts-list {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__cart {
    width: 370px;
    height: 370px;
    padding: 64px 20px 50px;
    border: 1px solid #BABFC3;
    border-radius: 8px;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &-header {
      @include setFonts("Roboto", 1.75rem, 2rem, 700);
      color: #595F67;
      margin-bottom: 7px;
    }

    &-subheader {
      @include setFonts("Roboto", 1rem, 1.5rem, 400);
      color: #6f6f6f;
    }
  }
}

@media screen and (max-width: 1023px) {
  .cta {
    &__carts-list {
      flex-wrap: wrap;
    }

    &__cart:not(:last-child) {
     margin: 0 0 20px 0;
    }
  }
}